import {
  ApolloClient,
  createHttpLink,
  NormalizedCacheObject,
  from,
  ApolloLink,
  RequestHandler,
  split,
} from '@apollo/client';
import { Socket as PhoenixSocket } from 'phoenix';
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink, AbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { DocumentNode, GraphQLError } from 'graphql';
import cache from './cache';
import { currentSpaceMiddleware } from './currentSpaceMiddleware';
export interface HeadersType {
  headers: {
    authorization: string;
  };
}
interface GraphQLErrorType extends GraphQLError {
  code: number;
}

export const customLink = (endLink: ApolloLink | RequestHandler, location = window.location) => {
  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors && graphQLErrors.length) {
      for (const err of graphQLErrors) {
        switch ((err as GraphQLErrorType).code) {
          case 401: {
            if (!['Session', 'GetOrganization'].includes(operation.operationName)) location.reload();
            return;
          }
          case 403:
          case 404: {
            const variables = operation.variables as { id: string };
            if (operation.operationName !== 'Login' && variables.id !== 'new-page' && variables.id !== 'signup') {
              location.assign('/error/page_not_found');
              return;
            }
          }
        }
      }
    }
  });

  return from([errorLink, currentSpaceMiddleware, endLink]);
};

export const WS_TOKEN = 'wsToken';

const getWSToken = () => {
  return sessionStorage.getItem(WS_TOKEN);
};

export const setWSToken = (token: string) => {
  sessionStorage.setItem(WS_TOKEN, token);
};

const generateWS = (endpoint: string) => {
  const phoenixSocket = new PhoenixSocket(endpoint);
  phoenixSocket.endPointURL = () => {
    return `${endpoint}?token=${getWSToken()}`;
  };

  const absintheSocket = AbsintheSocket.create(phoenixSocket);
  const websocketLink = createAbsintheSocketLink(absintheSocket);

  return websocketLink;
};

const generateClient = (
  endpoint = '',
  wsEndpoint = '',
  fetch = window.fetch,
  location = window.location,
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    fetch: fetch,
    uri: endpoint,
    credentials: 'include',
  });

  let wsLink: AbsintheSocketLink | undefined;

  if (wsEndpoint) {
    wsLink = generateWS(wsEndpoint);
  }

  const isSubscription = ({ query }: { query: DocumentNode }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  };

  const customHttpLink = customLink(httpLink, location);
  const link = wsLink ? split(isSubscription, wsLink as unknown as ApolloLink, customHttpLink) : customHttpLink;

  return new ApolloClient({
    link,
    cache,
  });
};

export default generateClient;
