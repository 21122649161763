export const passwordRules = [
  'Have at least one lower case character',
  'Be at least 8 characters',
  'Have at least one capital letter',
  'Not be the same as your email',
  'Have at least one number',
  'Cannot start or end with a blank space',
];

export const regexCheckPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^\s|\s$].{7}/;
