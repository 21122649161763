import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { generateTrackingParams } from 'utils';
import { APP_CONFIG } from 'api/config';
import oLogo from 'assets/o_logo.svg';
import omellaLogo from 'assets/logo_omella.svg';
import lockIcon from 'assets/lock.svg';
import './style.scss';

interface HeaderProps {
  className?: string;
  isCheckout?: boolean;
  tracking?: string;
  provider?: string;
  additionalAction?: ReactNode;
}

const Header: FC<HeaderProps> = ({ isCheckout, className, tracking, provider, additionalAction }: HeaderProps) => {
  return (
    <header className={clsx('main', className)}>
      <div className="logo">
        <a
          href={`${APP_CONFIG.OMELLA_WEBSITE}?utm_source=${window.location.href}&${generateTrackingParams(tracking)}`}
          target="_blank"
          rel="noreferrer">
          <picture>
            <source srcSet={omellaLogo} media="(min-width: 992px)" />
            <img src={oLogo} alt="Omella logo" />
          </picture>
        </a>
      </div>
      {isCheckout && provider === 'stripe' && (
        <span>
          <img src={lockIcon} alt="Lock icon" />
          Secured by <strong> Stripe</strong>
        </span>
      )}
      <div className="menu"></div>
      {additionalAction}
    </header>
  );
};

export default Header;
