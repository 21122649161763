import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { RouteComponentProps, useLocation } from 'wouter';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { requestsVar, deleteRequest, clearRequests } from 'api/data/request';
import * as PageQuery from 'graphql/page.graphql';
import * as RequestQuery from 'graphql/request.graphql';
import { showPlural, validateEmail } from 'utils';
import IconButton from 'components/Button/IconButton';
import Header from 'pages/MyPage/Header';
import Modal from 'components/Modal';
import RequestSentMessage from 'components/RequestSentMessage';
import SideModal from '~/components/SideModal';
import Tab from 'components/Tabs/Tab';
import TabContent from './TabContent';
import Tabs from 'components/Tabs';
import PayerPage from 'pages/PayerPage';
import { PageView } from 'api/data/pages/types';
import { Request, Requests } from 'api/data/request/types';
import './style.scss';

type PageParams = { id: string; location: string; tab?: string };

const tabs = ['requests', 'link', 'qrCode', 'embed'];
const locationMap: {
  [key: string]: boolean;
} = {
  editor: true,
  responses: true,
};

const SharePage: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { data: pageData, loading } = useQuery<PageView>(PageQuery.GetPageSummary, {
    variables: { id: params.id },
    fetchPolicy: 'no-cache',
  });
  const [deleteRequestMutation] = useMutation<Requests>(RequestQuery.DeleteRequest);
  const requests = useReactiveVar<Requests>(requestsVar);
  const [tabContentKey, setTabContentKey] = useState(params.tab && tabs.includes(params.tab) ? params.tab : 'link');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteRequestsModal, setShowDeleteRequestsModal] = useState(false);
  const [previewedIndex, setPreviewedIndex] = useState(-1);
  const [, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug() || '';
  const isValidLocation = !!locationMap[params.location];

  const requestIsPending = (request: Request) => (request.email || request.fullName) && request.status === 'UNSAVED';

  const pendingRequests = requests.filter(request => requestIsPending(request));

  const pendingRequestsQty = pendingRequests.length;

  const handleBackButton = () => {
    if (pendingRequests.length > 0) {
      setShowDeleteRequestsModal(true);
    } else {
      resetRequestsAndRedirect();
    }
  };

  const resetRequestsAndRedirect = () => {
    clearRequests();
    requests.forEach(request => {
      if (requestIsPending(request)) void deleteRequestMutation({ variables: { requestId: request.id } });
    });

    setLocation(`/${spaceSlug}/${isValidLocation ? params.location : 'pages'}/${page.slug}`);
  };

  if (loading || !pageData?.page) {
    return <div>Loading...</div>;
  }

  const { page } = pageData;

  const previewedRequestFullName = () => {
    return requests[previewedIndex]?.fullName || requests[previewedIndex]?.email || 'anonymous';
  };

  const disableEditButton = () =>
    !requests[previewedIndex]?.fullName || !validateEmail(requests[previewedIndex]?.email);

  const changeTab = (tab: string) => {
    setTabContentKey(tab);
    setPreviewedIndex(-1);
  };

  const toggleSideModal = () => setPreviewedIndex(-1);

  return (
    <>
      <Modal
        handleOnCancel={() => setShowDeleteModal(false)}
        handleOnConfirm={() => {
          void deleteRequestMutation({ variables: { requestId: requests[previewedIndex]?.id } });
          deleteRequest(previewedIndex);
          setPreviewedIndex(-1);
          setShowDeleteModal(false);
        }}
        confirmlabel="Delete"
        denyLabel="Cancel"
        header="Delete request"
        headerIcon="warning_circle"
        visible={showDeleteModal}
        className="delete-request-modal">
        Are you sure you want to delete this request?
      </Modal>
      <section onClick={() => setPreviewedIndex(-1)} role="presentation">
        <RequestSentMessage showMessage />
        <Modal
          handleOnCancel={() => setShowDeleteRequestsModal(false)}
          handleOnConfirm={() => {
            setShowDeleteRequestsModal(false);
            resetRequestsAndRedirect();
          }}
          confirmlabel="Leave and delete requests"
          denyLabel="Stay here"
          header="Delete requests"
          headerIcon="warning_circle"
          visible={showDeleteRequestsModal}
          className="delete-request-modal">
          <p>
            You have <b>{pendingRequestsQty} unsaved</b> request{showPlural(pendingRequestsQty)} that will be deleted if
            you leave this page.
          </p>
        </Modal>
        <div className="share-header">
          <Header title="Share Page" showLogo={false} linkBack="" onClickBack={handleBackButton} />
        </div>
        <section className={clsx('main-container', { 'open-preview': previewedIndex !== -1 })}>
          <div className="share-page">
            <div>
              <Tabs activeKey={tabContentKey}>
                <Tab label="Link" tabKey="link" onClick={changeTab}></Tab>
                <Tab label="QR Code" tabKey="qrCode" onClick={changeTab}></Tab>
                <Tab label="Embed" tabKey="embed" onClick={changeTab}></Tab>
                <Tab label="Requests" tabKey="requests" onClick={changeTab}></Tab>
              </Tabs>
            </div>
            <TabContent
              tabContentKey={tabContentKey}
              page={page}
              onShowPreview={setPreviewedIndex}
              selectedIndex={previewedIndex}
            />
          </div>
        </section>
      </section>
      <SideModal
        className="request-preview-modal"
        isOpen={previewedIndex !== -1}
        toggle={toggleSideModal}
        closeOnOutsideClick={false}
        header={
          <div className="right-pannel-header" data-testid="right-pannel">
            <IconButton icon="close" onClick={toggleSideModal} />
            <div className="title">
              <span>
                Previewing&nbsp;
                <strong>{previewedRequestFullName()}</strong>&apos;s request
              </span>
            </div>
            <div className="col actions">
              <button
                className="button-link edit-button action"
                disabled={disableEditButton()}
                onClick={() =>
                  setLocation(`/${spaceSlug}/editor/${page.slug}/requests/${requests[previewedIndex]?.id}`)
                }>
                Edit
              </button>
              <IconButton
                icon="delete"
                className="delete-button action"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              />
            </div>
          </div>
        }>
        <div className="right-pannel-content">
          <PayerPage params={{ id: params.id, previewOnly: 'true', requestId: requests[previewedIndex]?.id }} />
          <div className="spacing" />
        </div>
      </SideModal>
    </>
  );
};

export default SharePage;
