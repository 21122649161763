import React, { FC } from 'react';
import { useLocation } from 'wouter';
import { FetchResult, useReactiveVar } from '@apollo/client';
import { requestsVar, deleteRequestById } from 'api/data/request';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import useToggle from 'hooks/useToggle';
import useUserSession from 'hooks/useUserSession';
import IconButton from 'components/Button/IconButton';
import RequestSettings from 'components/PageSettings';
import SendRequestsButton from 'components/SendRequestsButton';
import Tooltip from 'components/Tooltip';
import { PageDataDraftBlock } from 'api/data/pages/types';
import { Requests as RequestsType, SendRequests } from 'api/data/request/types';
import './style.scss';

interface RequestHeaderProps {
  page: PageDataDraftBlock;
  requestId: string;
  handleSettingsChange: (attrs: Partial<PageDataDraftBlock>) => void;
  fullName?: string;
  email?: string;
}

const RequestHeader: FC<RequestHeaderProps> = ({ requestId, page, fullName, email, handleSettingsChange }) => {
  const { data: sessionData } = useUserSession();
  const [, setLocation] = useLocation();
  const [showSettingsModal, setShowSettingsModal] = useToggle(false);
  const requests = useReactiveVar<RequestsType>(requestsVar);

  if (!sessionData) return <div>Loading...</div>;

  const spaceSlug = currentSpaceSlug() || '';

  const handleOnSendRequests = async (sendRequests: (requestIds: string[]) => Promise<FetchResult<SendRequests>>) => {
    const result = (await sendRequests([requestId])).data?.sendRequests || { errors: [requestId], requests: [] };
    const failed = (result.errors?.length || 0) > 0;

    deleteRequestById(requestId);
    const filledRequests = requests.filter(request => request.email || request.fullName);

    if (filledRequests.length > 1 || failed) {
      setLocation(`/${spaceSlug}/pages/${page.slug}/share?${failed ? 'requestFailed=1' : 'requestSent=1'}`);
    } else {
      setLocation(`/${spaceSlug}/pages/${page.slug}?requestSent=1`);
    }
  };

  const isDraft = () => {
    return page.status === 'draft' || page.status === 'unsaved';
  };

  const handleDone = () => {
    if (isDraft()) {
      setLocation(`/${spaceSlug}/pages/${page.slug}/requests/share`);
    } else {
      setLocation(`/${spaceSlug}/pages/${page.slug}`);
    }
  };

  return (
    <nav className="actions request-navigation">
      <div className="fixed-navigation">
        <p>To: {`${fullName || ''} <${email || ''}>`} </p>
        <div>
          {isDraft() && (
            <SendRequestsButton
              disabled={false}
              pageId={page.id}
              onClick={sendRequestCallback => handleOnSendRequests(sendRequestCallback)}
              filledRequestsQty={1}
              buttonText="Send request"
              className="button-outline"
            />
          )}
          <button onClick={handleDone} className="done-button">
            Done
          </button>
          <div className="settings">
            <Tooltip title="Settings" color="dark" aria-label="settings">
              <IconButton icon="settings" onClick={() => setShowSettingsModal()} />
            </Tooltip>
          </div>
        </div>
      </div>
      <RequestSettings
        page={page}
        isSettingsOpen={showSettingsModal}
        toggleSettings={() => setShowSettingsModal(false)}
        handleConfigChanges={handleSettingsChange}
      />
    </nav>
  );
};

export default RequestHeader;
