import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import { hasPageStarted, hasPageEnded, daysUntilPageStarts, daysUntilPageEnds } from 'utils/pageAvailability';
import showPlural from 'utils/showPlural';
import { PageDataDraftBlock } from 'api/data/pages/types';
import './style.scss';

interface PageAvailabilityMessageProps {
  page: PageDataDraftBlock;
  currentDate: Dayjs;
}

const formattedCountdown = (days: number) => {
  const daysArray = days.toString().split('');

  return daysArray.map((day, index) => (
    <span key={index} className="countdown">
      {day}
    </span>
  ));
};

const PageAvailabilityMessage: FC<PageAvailabilityMessageProps> = ({ currentDate, page }) => {
  const message = () => {
    const daysUntilStart = daysUntilPageStarts(currentDate, page);
    const daysUntilEnd = daysUntilPageEnds(currentDate, page);
    const pageStarted = hasPageStarted(currentDate, page);
    const pageEnded = hasPageEnded(currentDate, page);

    if (pageEnded) {
      return 'This campaign has ended';
    }

    if (pageStarted && daysUntilEnd === undefined) {
      return 'This campaign has started';
    }

    if (!pageStarted && daysUntilStart !== undefined && daysUntilStart > 0) {
      return (
        <p>
          Starts in {formattedCountdown(daysUntilStart)} day{showPlural(daysUntilStart)}
        </p>
      );
    }

    if (!pageStarted && daysUntilStart !== undefined && daysUntilStart === 0) {
      return <p>Starts in less than a day</p>;
    }

    if (!pageEnded && daysUntilEnd !== undefined && daysUntilEnd === 0) {
      return <p>Ends in less than a day</p>;
    }

    if (!pageEnded && daysUntilEnd !== undefined && daysUntilEnd > 0) {
      return (
        <p>
          Ends in {formattedCountdown(daysUntilEnd)} day{showPlural(daysUntilEnd)}
        </p>
      );
    }
  };

  return <div className="page-availability-message">{message()}</div>;
};

export default PageAvailabilityMessage;
