import React, { FC } from 'react';
import omellaLogo from 'assets/logo_omella.svg';

const SimpleHeader: FC = () => {
  return (
    <header className="main simple-header">
      <div className="row space-between">
        <div className="logo">
          <img src={omellaLogo} alt="Omella logo" />
        </div>
      </div>
    </header>
  );
};

export default SimpleHeader;
