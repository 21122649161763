import { DefaultParams } from 'wouter';
import useUserSession from './useUserSession';
import { QueryHookOptions } from '@apollo/client';

const useParticipantLoggedIn = (params: DefaultParams | null, options?: QueryHookOptions) => {
  const { data: sessionData, loading } = useUserSession(options);

  const participant = sessionData?.session.participants?.find(participant => {
    return participant.page?.slug === params?.id;
  });

  const participantLoggedIn = !!participant && participant.slug === params?.participantSlug ? participant : undefined;

  return { logged: !!participantLoggedIn, participant: participantLoggedIn, p2pParticipant: participant, loading };
};

export default useParticipantLoggedIn;
