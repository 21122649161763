import { Options } from 'qr-code-styling';
import oLogo from 'assets/o_logo.svg';

const qrCode: Options = {
  width: 212,
  height: 212,
  data: '',
  margin: 0,
  qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'Q' },
  imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 8, crossOrigin: 'anonymous' },
  dotsOptions: { type: 'extra-rounded', color: '#092F3D' },
  backgroundOptions: { color: 'transparent' },
  image: oLogo,
  cornersSquareOptions: { type: 'extra-rounded', color: '#092F3D' },
  cornersDotOptions: { type: 'dot', color: '#092F3D' },
};

export default qrCode;
