import React, { FC, useState, SetStateAction, Dispatch } from 'react';
import clsx from 'clsx';
import { passwordRules } from 'defaults/user';
import checkIcon from 'assets/check_gray.svg';
import closedEyeIcon from 'assets/eye_closed.svg';
import eyeIcon from 'assets/eye_gray.svg';
import './style.scss';

type Password = { value: string; errorMessage: string };
type PasswordInputProps = {
  password: Password;
  setPassword: Dispatch<SetStateAction<Password>>;
  label: string;
};

const PasswordInput: FC<PasswordInputProps> = ({ password, setPassword, label }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="password">
      <label htmlFor="password-field">{label}</label>
      <div className="input-password">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="xxxxxxxx"
          autoComplete="off"
          value={password.value}
          onChange={({ target }) => {
            setPassword({ value: target.value, errorMessage: '' });
          }}
          id="password-field"
          name="new password"
          minLength={8}
          className={clsx('default', { 'has-error': !!password.errorMessage })}
        />
        <button type="button" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? (
            <img src={eyeIcon} alt="click to hide password" />
          ) : (
            <img src={closedEyeIcon} alt="click to show password" />
          )}
        </button>
      </div>
      {!!password.errorMessage && <span className="error-message">{password.errorMessage}</span>}
    </div>
  );
};

export const PasswordRules: FC<{ hasError: boolean }> = ({ hasError }) =>
  hasError ? (
    <div className="password-rules">
      <span>Password must:</span>
      <div>
        {passwordRules.map(rule => (
          <span key={rule}>
            <img src={checkIcon} alt="check icon" /> {rule}
          </span>
        ))}
      </div>
    </div>
  ) : null;

export default PasswordInput;
