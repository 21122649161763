import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'wouter';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as ReportsQuery from 'graphql/reports.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import { downloadFile } from 'utils';
import {
  buildSubmissionsReportTableConfig,
  extractSearchableDataFromResponse,
} from 'pages/Reports/Submissions/Columns/BuildSubmissionsReportTableConfig';
import useDebounce from '~/hooks/useDebounce';
import BlankState from 'components/BlankState';
import Columns from 'pages/Reports/Submissions/Columns';
import DropDownItem from '../Menu/DropDownItem';
import DropDown from '../Menu/DropDown';
import Input from '../Input';
import IconButton from 'components/Button/IconButton';
import ICONS from '../Icons';
import Modal from 'components/Modal';
import ReportTable from 'components/ReportTable';
import SubmissionDetailsTabs from '~/pages/SubmissionDetails/Tabs';
import Tooltip from 'components/Tooltip';
import { ConfigurationType, SortType, TableConfigType } from 'pages/Reports/Submissions/types';
import { DEFAULT_VIEW, ViewType, VisibleColumnsType } from 'pages/Reports/Submissions/types';
import {
  PageSpreadsheetUrl,
  ResponseReportRecord,
  SubmissionQuestion,
  SubmissionsReportQuestions,
  SubmissionsReportResponses,
} from 'api/data/response/types';
import { SubmissionsReportDownload } from 'api/data/response/types';
import './styles.scss';

interface ReportingTableProps {
  pageId: string;
  tabParam?: string;
}

const PageReport: FC<ReportingTableProps> = ({ pageId, tabParam }) => {
  const [location, setLocation] = useLocation();
  const [currentView, setCurrentView] = useState<ViewType>({
    id: 'page_report',
    name: 'page_report',
    configuration: {
      query: {
        ...DEFAULT_VIEW.configuration.query,
        sort: DEFAULT_VIEW.configuration.query.sort,
        columns: { ...DEFAULT_VIEW.configuration.query.columns, page: false },
        filters: { pageIds: [pageId] },
      },
    },
  });

  const [questions, setQuestions] = useState<SubmissionQuestion[]>();
  const [tableData, setTableData] = useState<ResponseReportRecord[]>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showModalError, setShowModalError] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filteredTableData, setFilteredTableData] = useState<ResponseReportRecord[]>();
  const [pageSpreadsheet, { loading: spreadsheetLoading }] = useMutation<PageSpreadsheetUrl>(
    ResponseQuery.PageSpreadsheet,
    { variables: { pageId } },
  );
  const [selectedRow, setSelectedRow] = useState<ResponseReportRecord>();

  const refOutside = useRef<HTMLElement>(null);

  useEffect(() => {
    if (tabParam) {
      const newLocation = location.replace(`/${tabParam}`, '');
      setLocation(newLocation);
    }
  }, [tabParam, location, setLocation]);

  const { query } = currentView.configuration;

  const setIfUndefined = (map: VisibleColumnsType, key: string) => {
    if (map[key] === undefined) map[key] = true;
  };

  const checkAllQuestionsAsVisible = (questions: SubmissionQuestion[]) => {
    questions.forEach(page => {
      setIfUndefined(currentView.configuration.query.columns, page.page_id);
      page.questions.forEach(q => {
        setIfUndefined(currentView.configuration.query.columns, q.block_id);
      });
      page.response_activity_types.forEach(rat => {
        setIfUndefined(currentView.configuration.query.columns, rat.id);
      });
    });
  };

  const { loading: questionsLoading } = useQuery<SubmissionsReportQuestions>(ReportsQuery.SubmissionsReportQuestions, {
    variables: {
      input: {
        query: {
          filters: query.filters,
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: result => {
      setQuestions(result.submissionsReportQuestions);
      checkAllQuestionsAsVisible(result.submissionsReportQuestions);
    },
  });

  const { loading: responsesLoading } = useQuery<SubmissionsReportResponses>(ReportsQuery.SubmissionsReportResponses, {
    variables: {
      input: {
        query: {
          filters: query.filters,
          sort: query.sort,
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: result => {
      setTableData(result.submissionsReportResponses);
    },
  });

  const setSort = (sort: SortType) => {
    void setCurrentViewQuery({
      ...currentView.configuration.query,
      sort: sort,
    });
  };

  const setCurrentViewQuery = (query: ConfigurationType['query']) => {
    const newCurrentView = {
      ...currentView,
      configuration: {
        ...currentView.configuration,
        query: query,
      },
    };
    setCurrentView(newCurrentView);
  };

  const setVisibleColumns = (columns: VisibleColumnsType) => {
    void setCurrentViewQuery({
      ...currentView.configuration.query,
      columns: columns,
    });
  };

  const [downloadReportQuery, { loading: loadingDownload }] = useLazyQuery<SubmissionsReportDownload>(
    ReportsQuery.SubmissionsReportDownload,
    {
      onCompleted: data => {
        downloadFile('report.csv', data.submissionsReportDownload.content);
      },
      fetchPolicy: 'network-only',
    },
  );

  const downloadReport = () => {
    void downloadReportQuery({
      variables: {
        input: {
          query: {
            sort: currentView.configuration.query.sort,
            filters: currentView.configuration.query.filters,
            columns: JSON.stringify(currentView.configuration.query.columns),
          },
        },
      },
    });
  };

  const openInGoogleSheets = async () => {
    try {
      const { data } = await pageSpreadsheet();
      if (data?.pageSpreadsheet && data?.pageSpreadsheet.spreadsheetUrl) {
        window.open(data.pageSpreadsheet.spreadsheetUrl, '_blank')?.focus();
      }
    } catch {
      setShowModalError(true);
    }
  };

  const tableConfig = useMemo(() => {
    const originalTableConfig = buildSubmissionsReportTableConfig(currentView.configuration, questions);

    return {
      ...originalTableConfig,
      ungroupedColumns: originalTableConfig.ungroupedColumns.filter(
        column => column.fieldName !== 'page' && column.fieldName !== 'space',
      ),
      onRowClicked: (data: TableConfigType) => {
        setSelectedRow(data as ResponseReportRecord);
      },
    };
  }, [currentView, questions]);

  useEffect(() => {
    if (!tableData) return;
    setFilteredTableData(
      tableData?.filter(response => {
        return extractSearchableDataFromResponse(tableConfig, response).some(field =>
          field.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
        );
      }),
    );
  }, [tableData, setFilteredTableData, debouncedSearchTerm, tableConfig]);

  const blankState = debouncedSearchTerm ? (
    <BlankState title="No results found" message="" />
  ) : (
    <BlankState title="No submissions" message="Data will appear here." />
  );

  const googleSheetProps = {
    icon: spreadsheetLoading ? 'spinner' : 'google_sheets',
    disabled: spreadsheetLoading,
    onClick: () => void openInGoogleSheets(),
  };

  const csvProps = {
    icon: loadingDownload ? 'spinner' : 'download_file',
    disabled: loadingDownload,
    onClick: () => void downloadReport(),
  };

  return (
    <div className="page-report">
      <div className="page-report-filters">
        <div className="search-filter">
          {ICONS['search']}
          <Input
            type="text"
            aria-label="search"
            name="search"
            placeholder="Search"
            value={searchTerm}
            onChange={({ target }) => {
              void setSearchTerm(target.value);
            }}
          />
        </div>
      </div>
      <div className="page-report-actions">
        <IconButton
          icon={googleSheetProps.icon}
          disabled={googleSheetProps.disabled}
          onClick={googleSheetProps.onClick}
          className="google-sheets desktop-only"
        />
        <Tooltip title="Download CSV" color="dark">
          <IconButton
            icon={csvProps.icon}
            disabled={csvProps.disabled}
            onClick={csvProps.onClick}
            className={clsx(loadingDownload ? 'loading' : '', 'desktop-only')}
          />
        </Tooltip>
        <Columns
          questions={questions || []}
          fixedColumns={tableConfig.ungroupedColumns}
          visibleColumns={currentView.configuration.query.columns}
          setVisibleColumns={setVisibleColumns}
        />
        <DropDown className="mobile-only" outsideRef={refOutside} openedIcon="menu" closedIcon="menu_open">
          <DropDownItem
            icon={googleSheetProps.icon}
            disabled={googleSheetProps.disabled}
            onClick={googleSheetProps.onClick}>
            Google Sheet
          </DropDownItem>
          <DropDownItem
            icon={csvProps.icon}
            disabled={csvProps.disabled}
            onClick={csvProps.onClick}
            className={loadingDownload ? 'loading' : ''}>
            Download CSV
          </DropDownItem>
        </DropDown>
      </div>
      <ReportTable
        tableConfig={tableConfig}
        tableData={filteredTableData}
        setSort={setSort}
        blankState={blankState}
        currentSort={currentView.configuration.query.sort}
        loading={questionsLoading || responsesLoading}
        getItemId={(data: TableConfigType) => data.id || ''}
      />
      <Modal handleOnCancel={() => setShowModalError(false)} visible={showModalError} className="error-modal-response">
        <h4>Oops!</h4>
        <p>Something went wrong.</p>
        <p>Please try reloading this page, or coming back to it in a few minutes.</p>
      </Modal>
      <Modal
        className="submission-details-modal"
        handleOnCancel={() => setSelectedRow(undefined)}
        handleOnConfirm={() => setSelectedRow(undefined)}
        confirmlabel="Ok"
        visible={!!selectedRow}
        header="Details"
        fullScreenMobile>
        {selectedRow && <SubmissionDetailsTabs responseId={selectedRow.id} />}
      </Modal>
    </div>
  );
};

export default PageReport;
