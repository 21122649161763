import React, { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery, useApolloClient, ApolloClient, NormalizedCacheObject, useMutation } from '@apollo/client';
import { RouteComponentProps, Link, useLocation } from 'wouter';
import { loadStripe } from '@stripe/stripe-js';
import queryString from 'query-string';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { APP_CONFIG } from 'api/config';
import { updateResponse, updateAnswer, updateMultipleChoiceAnswer } from 'api/data/response';
import * as PageQuery from 'graphql/page.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import { currencyFormatter, generateTracking, isEmbed, numberCentsFormatter, pickKeys, validateEmail } from 'utils';
import { defaultResponse } from 'defaults/response';
import Header from '../Header';
import ItemsDetailsModal, { ItemDetails } from './ItemsDetailsModal';
import { termsPrivacyDescription } from 'components/ACH';
import PaymentMethodOptions from 'components/PaymentMethodOptions';
import TermsPrivacy from 'components/TermsPrivacy';
import Wallet from 'components/Wallet';
import { saveResponseAndProcessPayment } from 'api/data/payment';
import { WalletProps } from 'components/Wallet/types';
import { PageView } from 'api/data/pages/types';
import { Response, ResponseInfo, ResponseData } from 'api/data/response/types';
import { ProcessPaymentData } from 'api/data/payment/types';
import arrowRight from 'assets/arrow_right.svg';
import arrowLeft from 'assets/arrow_left.svg';
import './style.scss';

export type PaymentMethod = 'card' | 'wallet' | 'ach';

type PageParams = {
  id: string;
  responseId?: string;
};

const WalletWrapper: FC<WalletProps> = ({ ...props }) => {
  const stripe = useStripe();
  return <Wallet {...props} stripe={stripe} />;
};

const stripePromise = loadStripe(APP_CONFIG.STRIPE);

const Checkout: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { id: pageId, responseId } = params;
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('wallet');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [, setLocation] = useLocation();
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  const {
    data: responseData,
    loading: loadingResponse,
    error: errorResponse,
  } = useQuery<ResponseInfo>(ResponseQuery.GetResponse, {
    variables: { id: responseId },
  });
  const response = responseData?.response;

  const [saveResponse] = useMutation<ResponseData>(ResponseQuery.SaveResponse);

  const {
    data: pageData,
    error,
    loading,
  } = useQuery<PageView>(PageQuery.GetPage, {
    variables: { id: pageId, requestId: response?.requestId },
    skip: !response,
  });
  const checkoutPrefilled = useRef(false);
  const priceByPaymentMethod =
    paymentMethod === 'wallet'
      ? { ...response?.order?.metadata?.pricing?.card, paymentMethod: 'card' }
      : { ...response?.order?.metadata?.pricing?.[paymentMethod], paymentMethod };
  const orderIsProcessed = response?.order?.status?.toLocaleUpperCase() === 'PROCESSED';

  useEffect(() => {
    if (errorResponse) {
      setLocation(`/${pageId}`);
    }
  }, [errorResponse, pageId, setLocation]);

  useEffect(() => {
    if (loadingResponse) return;

    if (
      (response?.order?.status === 'PROCESSED' && response?.order?.lastPaymentStatus !== 'FAILED') ||
      (response && response?.page?.slug !== pageId && response?.pageId !== pageId)
    ) {
      updateResponse({ ...defaultResponse, id: undefined });
      setLocation(`/${pageId}`);
      return;
    }

    if (!checkoutPrefilled.current && response) {
      updateResponse({
        ...response,
        tracking: generateTracking(),
        answers: [],
      });

      response.answers?.forEach(answer => {
        if (answer.question.type === 'MULTIPLE_CHOICE') {
          updateMultipleChoiceAnswer(answer.question.id, answer);
        } else {
          updateAnswer(answer.question.id, answer, answer.group);
        }
      });
      checkoutPrefilled.current = true;
    }
  }, [responseId, response, loadingResponse, pageId, setLocation]);

  useEffect(() => {
    if ('parentIFrame' in window) {
      window.parentIFrame.scrollToOffset(0, -50);
    }
  }, []);

  if (error) {
    return <div data-testid="error-div">Error</div>;
  }

  if (!pageData || loading || loadingResponse || !response) {
    return <div>Loading...</div>;
  }

  const handleResponseUser = (value: string, field: string) => {
    updateResponse({
      user: { ...(response.user ? response.user : { email: '', zipcode: '', fullName: '' }), [field]: value },
    });
  };

  const clearResponseUser = () => {
    updateResponse({
      user: undefined,
    });
  };

  const saveEarlyResponse = async (newResponse: Response) => {
    if (
      validateEmail(newResponse.user?.email || '') &&
      response.status &&
      response.status.toLocaleUpperCase() !== 'PROCESSED'
    ) {
      const res = { ...newResponse };
      res.email = res.user?.email;
      res.fullName = res.user?.fullName;
      if (!res.fullName || !res.email) delete res.user;

      await saveResponse({
        variables: {
          response: {
            ...pickKeys(res, 'id', 'pageId', 'email', 'fullName'),
            user: res.user && pickKeys(res.user, 'fullName', 'email'),
          },
        },
      });
    }
  };

  const participant = queryString.parse(window.location.search).participant as string;

  const processPaymentAndRedirect = async (processPaymentData: ProcessPaymentData) => {
    const { succeeded } = await saveResponseAndProcessPayment(client, response, processPaymentData);

    if (pageData?.page.afterSubmissionUrl) {
      window.location.assign(pageData.page.afterSubmissionUrl);
    } else {
      setLocation(
        `/submissions/${response.id}?type=${succeeded ? 'payment' : 'payment_sent'}${participant ? `&participant=${participant}` : ''}`,
      );
    }
  };

  const getTotalCents = () => {
    if (paymentMethod === 'ach') return response.order.metadata?.pricing?.ach.totalCents;

    return response.order.metadata?.pricing?.card.totalCents;
  };

  if (response.user?.email) {
    handleResponseUser(response.user?.email, 'email');
  }

  const backLocation = () => {
    if (response.requestId) {
      return `/requests/${response.requestId}`;
    }

    const baseUrl = `${pageId || ''}`;
    if (participant) return `/${baseUrl}/p/${participant}`;
    return `/${baseUrl}`;
  };

  return (
    <main className={clsx('checkout-page', { 'disable-interactions': paymentProcessing })}>
      <ItemsDetailsModal
        showModal={showDetailModal}
        setShowModal={setShowDetailModal}
        order={response.order}
        hasTip={pageData.page.tipsEnabled}
        blocks={pageData.page.blocks}
        orderIsProcessed={orderIsProcessed}
        priceByPaymentMethod={priceByPaymentMethod}
      />
      {!isEmbed() && (
        <Header
          isCheckout
          tracking={response.tracking}
          className="modal-page-header"
          provider={pageData.page.organization?.provider}
        />
      )}
      {!orderIsProcessed && (
        <Link href={backLocation()} className="link-back" onClick={clearResponseUser}>
          <img src={arrowLeft} alt="back icon" /> Back
        </Link>
      )}
      <div className="payment-container">
        <div className="row align-center header-container">
          <div className="summary-box">
            <div className="total-box">
              <span>Total</span>
              <h3>{currencyFormatter(priceByPaymentMethod.totalCents, true)}</h3>
            </div>
            <span
              role="presentation"
              className="row space-between detail-modal-btn"
              onClick={() => setShowDetailModal(true)}>
              {response.order.orderItems.length} item
              <img src={arrowRight} alt="arrow icon" />
            </span>
            {pageData.page.logoUrl ? (
              <div className="view-page-logo">
                <img className="view-page-logo-img" src={pageData.page.logoUrl} alt="company logo" />
              </div>
            ) : null}
          </div>
          <ItemDetails
            order={response.order}
            hasTip={pageData.page.tipsEnabled}
            orderIsProcessed={orderIsProcessed}
            blocks={pageData.page.blocks}
            priceByPaymentMethod={priceByPaymentMethod}
          />
        </div>
        <div className="payment-options">
          <Elements stripe={stripePromise}>
            <WalletWrapper
              pageId={pageId}
              paymentMethod={paymentMethod}
              handleOnChange={setPaymentMethod}
              pageTitle={pageData.page.title}
              stripe={null}
              paymentProcessing={setPaymentProcessing}
              provider={pageData.page.organization?.provider || ''}
              response={response}
            />
          </Elements>
          <PaymentMethodOptions
            onSubmit={processPaymentAndRedirect}
            setLoading={setPaymentProcessing}
            loading={paymentProcessing}
            onResponseChanged={res => void saveEarlyResponse(res)}
            buttonText={`Pay $${numberCentsFormatter(getTotalCents() || 0)}`}
            pageId={pageId}
            response={response}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            paymentError={{ title: 'Failed to pay' }}
            provider={pageData.page.organization?.provider}
            disableInputEmail={orderIsProcessed}
          />
          <TermsPrivacy type="PAYMENT" additionalDescription={paymentMethod === 'ach' ? termsPrivacyDescription : ''} />
        </div>
      </div>
    </main>
  );
};

export default Checkout;
