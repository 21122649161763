import React, {
  FC,
  useState,
  useRef,
  useCallback,
  useEffect,
  KeyboardEvent,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { EditorState, DraftHandleValue, RichUtils } from 'draft-js';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import dayjs from 'dayjs';
import { ApolloError } from '@apollo/client/errors';
import uploadFile from 'api/uploadClient';
import { deleteBlock } from '~/api/data/pages/blocks';
import { generateEditorState } from 'api/data/pages';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useOutsideClick from 'hooks/useOutsideClick';
import useSpacesFromCurrentOrg from 'hooks/useSpacesFromCurrentOrg';
import { defaultPageTitle } from 'defaults/page';
import { onImageInputClick } from 'utils';
import {
  adjustBlockDepthForContentState,
  appendTextBlocks,
  isEmpty,
  moveFocus,
  splitAllBlocks,
  splitBlock,
} from 'components/RichTextEditor/utils';
import Banner from '../Banner';
import AddDatesModal from 'components/Editor/AddDatesModal';
import ContainerBlock from 'components/Editor/ContainerBlock';
import Donation from 'components/DonationBlock';
import DraggerMenu from 'components/Editor/Dragger';
import ErrorMessage from 'components/ErrorMessage';
import ErrorModal from 'components/ErrorModal';
import GoalBar, { getTarget } from 'components/GoalBar';
import Group from 'components/GroupBlock';
import ICON from 'components/Icons';
import IconButton from 'components/Button/IconButton';
import Payment from 'components/PaymentBlock';
import ProtectedComponent from 'components/ProtectedComponent';
import Question from 'components/QuestionBlock';
import ResizableTextArea from 'components/ResizableTextArea/index';
import RichTextEditor from 'components/RichTextEditor';
import SideMenu from 'components/Editor/SideMenu';
import SupporterFeed from 'components/SupporterFeedBlock';
import UploadImageWrapper from 'components/UploadImage';
import UploadMenu from 'components/UploadMenu';
import PageAvailabilityMessage from 'components/Editor/PageAvailabilityMessage';
import { DonationBlock } from 'components/DonationBlock/types';
import { Goal as GoalType } from 'components/GoalBar/types';
import { GroupBlock } from 'components/GroupBlock/types';
import { PaymentBlock } from 'components/PaymentBlock/types';
import { QuestionBlock } from 'components/QuestionBlock/types';
import { SupporterFeedBlock } from 'components/SupporterFeedBlock/types';
import { PageDataDraftBlock } from 'api/data/pages/types';
import { BlockState, BlockTextState } from 'api/data/pages/blocks/types';
import './style.scss';

type EditorProps = {
  requestId?: string;
  setPageTitle?: (title: string) => void;
  page: PageDataDraftBlock;
  setPage: Dispatch<SetStateAction<PageDataDraftBlock>>;
  savePageError?: ApolloError;
};

const Editor: FC<EditorProps> = ({ page, requestId, setPageTitle, children, setPage, savePageError }) => {
  const [focusedElement, setFocusedElement] = useState<number>(0);
  const [imageUploading, setImageUploading] = useState('');
  const [reloadPageError, setReloadPageError] = useState(false);
  const [createTextBlockAtTop, setCreateTextBlockAtTop] = useState(false);

  const { currentOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();

  const refOutside = useRef<HTMLElement>(null);
  const refInside = useRef<HTMLElement>(null);
  const logoInput = useRef<HTMLInputElement>(null);
  const coverInput = useRef<HTMLInputElement>(null);
  const refImages = useRef<HTMLDivElement>(null);

  const handleOnChange = (value: EditorState, i: number) => {
    const newBlocks = [...page.blocks];
    newBlocks[i] = { ...newBlocks[i], data: value } as BlockState;
    setPage({ ...page, blocks: newBlocks });
  };

  useEffect(() => {
    if (savePageError) setReloadPageError(true);
  }, [savePageError]);

  const handleKeyCommand = (command: string, currentState: EditorState, index: number): DraftHandleValue => {
    const onTab = (shifted: boolean) => {
      if (currentState) {
        const withAdjustment = adjustBlockDepthForContentState(currentState, shifted, 4);
        const newState = EditorState.push(currentState, withAdjustment, 'adjust-depth');
        handleOnChange(newState, index);
      }
    };

    const newBlocks = [...page.blocks];

    if (
      (command === 'editor-delete-block' && index > 0) ||
      (command === 'editor-delete-next-block' && index < page.blocks.length - 1)
    ) {
      let blockIndex = index - 1;
      if (command === 'editor-delete-next-block') {
        blockIndex = index + 1;
      }
      if (page.blocks[blockIndex].type !== 'TEXT' && currentState.getCurrentContent().hasText()) {
        return 'handled';
      }

      if (page.blocks[blockIndex].type === 'TEXT') {
        const previousBlock = page.blocks[blockIndex] as BlockTextState;
        const { data: prevData } = previousBlock;
        const contentState = prevData.getCurrentContent();
        const selectionState = prevData.getSelection();
        const currentBlockKey = selectionState.getAnchorKey();
        const currentBlock = contentState.getBlockForKey(currentBlockKey);
        if (currentBlock.getType() === 'atomic') {
          return 'handled';
        }

        newBlocks[blockIndex] = {
          ...newBlocks[blockIndex],
          data:
            command === 'editor-delete-block'
              ? appendTextBlocks(prevData, currentState)
              : appendTextBlocks(currentState, prevData),
        } as BlockState;
      }

      newBlocks.splice(index, 1);
      setPage({ ...page, blocks: newBlocks });
      return 'handled';
    }

    if (command === 'editor-tab') {
      onTab(false);
      return 'handled';
    }
    if (command === 'editor-tab-shift') {
      onTab(true);
      return 'handled';
    }

    if (command === 'editor-new-line') {
      const [previousEditorState, newEditorState] = splitBlock(currentState);
      const newTextBlock = generateEditorState();
      const editorBlock = newBlocks[index] as BlockTextState;
      newBlocks.splice(index, 1, { ...editorBlock, data: previousEditorState });
      newBlocks.splice(index + 1, 0, { ...newTextBlock, data: moveFocus(newEditorState, 0) });
      setPage({ ...page, blocks: newBlocks });
      setFocusedElement(index + 1);
      return 'handled';
    }

    if (
      (command === 'editor-arrow-up' && index > 0) ||
      (command === 'editor-arrow-down' && index < page.blocks.length - 1)
    ) {
      let blockIndex = index - 1;
      if (command === 'editor-arrow-down') {
        blockIndex = index + 1;
      }
      if (page.blocks[blockIndex].type !== 'TEXT') {
        return 'handled';
      }
      const editorBlock = newBlocks[blockIndex] as BlockTextState;

      newBlocks.splice(blockIndex, 1, {
        ...editorBlock,
        data: command === 'editor-arrow-down' ? moveFocus(editorBlock.data, 0, true) : moveFocus(editorBlock.data, 0),
      });
      setPage({ ...page, blocks: newBlocks });
      return 'handled';
    }

    const copyData = page.blocks[index] as BlockTextState;
    const editorData = copyData.data;
    if (editorData) {
      const newState = RichUtils.handleKeyCommand(editorData, command);
      if (newState) {
        handleOnChange(newState, index);
        return 'handled';
      }
    }

    return 'not-handled';
  };

  const handleFocus = useCallback(() => {
    const newBlocks = [...page.blocks];
    const idx = newBlocks.length - 1;
    const newBlockData = newBlocks[idx] as BlockTextState;
    const data = newBlockData?.data;
    const hasEmptyText = isEmpty(data);
    if (hasEmptyText) {
      const data = newBlockData.data;
      if (data) {
        const focusEnd = EditorState.moveFocusToEnd(data);
        newBlocks[idx] = { ...newBlocks[idx], data: focusEnd } as BlockState;
        setFocusedElement(idx);
        setPage({ ...page, blocks: newBlocks });
      }
    } else {
      const newBlocks = [...page.blocks];
      newBlocks.splice(idx + 1, 0, generateEditorState());
      setPage({ ...page, blocks: newBlocks });
      setFocusedElement(idx + 1);
    }
  }, [page, setPage]);

  useOutsideClick(refInside, refOutside, (event?: Event) => {
    if (!refImages?.current?.contains(event?.target as Node)) {
      handleFocus();
    }
  });

  const changeTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
      nativeEvent,
    } = event;

    const { inputType } = nativeEvent as InputEvent;

    if (inputType !== 'insertLineBreak') {
      const newTitle = value.replace(/^[\n|\r]|[\n|\r]+$/gm, '').trimStart();

      const regex = /\n|\r/gm;
      if (regex.test(newTitle)) {
        const newEditorState = createEditorStateWithText(newTitle);
        const newBlock = { ...generateEditorState(), data: newEditorState };
        const newPageBlocks = [newBlock, ...page.blocks];

        setPage({ ...page, blocks: newPageBlocks });
        return;
      }

      setPage({ ...page, title: newTitle || defaultPageTitle });
      setPageTitle && setPageTitle(newTitle || defaultPageTitle);
      return;
    }

    if (createTextBlockAtTop) {
      const splitTitle = value.split('\n');
      const newEditorState = createEditorStateWithText(splitTitle[1]);
      const newBlock = { ...generateEditorState(), data: moveFocus(newEditorState, 0) };
      const newPageBlocks = [newBlock, ...page.blocks];

      setPage({ ...page, title: splitTitle[0], blocks: newPageBlocks });
      setFocusedElement(0);
    }
  };

  const handleEnterInTitle = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const { key, shiftKey } = event;

    if ((key === 'Enter' || key === 'NumpadEnter') && !shiftKey) {
      setCreateTextBlockAtTop(true);
    } else {
      setCreateTextBlockAtTop(false);
    }
  };

  const replaceBlockAt = (block: BlockState, blockIdx: number) => {
    const copyBlocks = [...page.blocks];
    copyBlocks.splice(blockIdx, 1, block);
    return copyBlocks;
  };

  const setGoal = (block: BlockState, blockIdx: number, newBlocks?: BlockState[]) => {
    const blocks = newBlocks || replaceBlockAt(block, blockIdx);
    const target = getTarget(blocks);

    const newGoal: GoalType | null =
      target === 0
        ? null
        : {
            target,
            collectedAmount: page.goal?.collectedAmount || 0,
            submissionsCount: page.goal?.submissionsCount || 0,
          };

    setPage({
      ...page,
      goal: newGoal,
      blocks,
    });
  };

  const updateBlockInPage = (block: BlockState, blockIdx: number) => {
    const blocks = replaceBlockAt(block, blockIdx);
    setPage({ ...page, blocks });
  };

  const handleDeletePaymentBlock = (blockIdx: number, groupIdx?: number) => {
    const newBlocks = deleteBlock(page.blocks, blockIdx, groupIdx);
    setGoal({} as BlockState, blockIdx, newBlocks);
  };

  const handleDeleteBlock = (blockIdx: number, groupIdx?: number) => {
    const blocks = deleteBlock(page.blocks, blockIdx, groupIdx);
    setPage({ ...page, blocks });
  };

  const handleEmbedVideo = (editorState: EditorState, blockIdx: number) => {
    const textBlocks = splitAllBlocks(editorState);
    const newBlocks = [...page.blocks];

    newBlocks.splice(blockIdx, 1);

    textBlocks.forEach((textBlock, idx) => {
      newBlocks.splice(blockIdx + idx, 0, { ...generateEditorState(), data: textBlock });
    });

    setPage({ ...page, blocks: newBlocks });
    setFocusedElement(blockIdx + 1);
  };

  const blockComponents = (blockItem: BlockState, blockIdx: number) => {
    const isFocused = focusedElement === blockIdx;

    return {
      TEXT: (
        <RichTextEditor
          key={blockItem.id}
          editorState={(blockItem as BlockTextState).data}
          onChange={(value: EditorState) => handleOnChange(value, blockIdx)}
          placeholder="Click + to add"
          handleKeyCommand={(command, editorState) => handleKeyCommand(command, editorState, blockIdx)}
          onFocus={() => setFocusedElement(blockIdx)}
          handleDelete={() => handleDeleteBlock(blockIdx)}
          handleEmbedVideo={editorState => handleEmbedVideo(editorState, blockIdx)}
        />
      ),
      DONATION: (
        <Donation
          isFocused={isFocused}
          key={blockItem.id}
          data={blockItem as DonationBlock}
          refOutside={refOutside}
          setGoal={block => setGoal(block, blockIdx)}
          handleDelete={() => handleDeletePaymentBlock(blockIdx)}
          updateBlock={block => updateBlockInPage(block, blockIdx)}
        />
      ),
      PAYMENT: (
        <Payment
          isFocused={isFocused}
          key={blockItem.id}
          data={blockItem as PaymentBlock}
          refOutside={refOutside}
          isRequest={!!requestId}
          setGoal={block => setGoal(block, blockIdx)}
          updateBlock={block => updateBlockInPage(block, blockIdx)}
          handleDelete={() => handleDeletePaymentBlock(blockIdx)}
        />
      ),
      QUESTION: (
        <Question
          isFocused={isFocused}
          key={blockItem.id}
          data={blockItem as QuestionBlock}
          refOutside={refOutside}
          handleDelete={() => handleDeleteBlock(blockIdx)}
          updateBlock={block => updateBlockInPage(block, blockIdx)}
        />
      ),
      GROUP: (
        <Group
          key={blockItem.id}
          data={blockItem as GroupBlock}
          groupIndex={blockIdx}
          pageBlocks={page.blocks}
          isFocused={isFocused}
          setBlocks={newblocks => setPage({ ...page, blocks: newblocks })}
          refOutside={refOutside}
          handleDelete={(groupIdx?: number) => handleDeleteBlock(blockIdx, groupIdx)}
          updateBlock={block => updateBlockInPage(block, blockIdx)}
        />
      ),
      SUPPORTER_FEED: (
        <SupporterFeed
          isFocused={isFocused}
          key={blockItem.id}
          data={blockItem as SupporterFeedBlock}
          refOutside={refOutside}
          handleDelete={() => handleDeleteBlock(blockIdx)}
          updateBlock={block => updateBlockInPage(block, blockIdx)}
        />
      ),
    };
  };

  const handleOnUploadImage = async (file: File, attr: string) => {
    const uploadResponse = await uploadFile(file, 'IMAGE', attr, page.id, requestId);
    setPage({ ...page, [attr]: uploadResponse.file_handle, [`${attr}Url`]: uploadResponse.url });
  };

  const removeImage = (attr: string) => {
    setPage({ ...page, [attr]: '', [`${attr}Url`]: '' });
  };

  const datesModal = (buttonClassName: string, label?: string) => (
    <ProtectedComponent
      action="PAGE_SETTINGS"
      currentUserOrganizationRole={currentOrg?.currentUserRole}
      currentUserSpaceRole={currentSpace?.currentUserRole}>
      {!!page.organizationId && (
        <AddDatesModal page={page} setPage={setPage} label={label} buttonClassName={buttonClassName} />
      )}
    </ProtectedComponent>
  );

  if (page.id && !page.organizationId) return <div>Loading...</div>;

  const currentDate = dayjs();

  const hasAnyDateDefined = !!(page.startDate || page.endDate);

  return (
    <>
      <main ref={refOutside} className="editor-main">
        {children}
        {hasAnyDateDefined && (
          <div className="message-wrapper">
            <PageAvailabilityMessage page={page} currentDate={currentDate} />
            {datesModal('button-ghost button-size-sm edit-dates', 'Edit')}
          </div>
        )}
        {page.isTemplate && <Banner type="warning"> ⚠️ This is a template ⚠️ </Banner>}
        <div className="img-header" ref={refImages}>
          <UploadImageWrapper
            image={{
              name: 'coverPicture',
              label: 'cover',
              file: page.coverPictureUrl,
              ref: coverInput,
            }}
            imageUploading={imageUploading}
            handleOnRemoveImage={removeImage}
            handleOnUploadImage={handleOnUploadImage}
            setImageUploading={setImageUploading}>
            <UploadMenu outsideRef={refOutside} inputRef={coverInput} deletePhoto={() => removeImage('coverPicture')}>
              Edit cover
            </UploadMenu>
          </UploadImageWrapper>
          <UploadImageWrapper
            image={{
              name: 'logo',
              label: 'logo',
              file: page.logoUrl,
              ref: logoInput,
            }}
            imageUploading={imageUploading}
            handleOnRemoveImage={removeImage}
            handleOnUploadImage={handleOnUploadImage}
            setImageUploading={setImageUploading}>
            <UploadMenu outsideRef={refOutside} inputRef={logoInput} deletePhoto={() => removeImage('logo')}>
              Edit logo
            </UploadMenu>
          </UploadImageWrapper>
        </div>
        <section ref={refInside} className="editor-section" data-testid="editor-page">
          {reloadPageError && <ErrorMessage message="Something went wrong, please reload the page" />}
          <div className="container image-button-container">
            {!page.logo && (!imageUploading || imageUploading === 'cover') && (
              <IconButton icon="image_upload" onClick={() => onImageInputClick(logoInput)} className="add-image-button">
                Add logo
              </IconButton>
            )}
            {!!imageUploading && imageUploading === 'logo' && (
              <span className="loading">
                {ICON['spinner']} Uploading {imageUploading}...
              </span>
            )}
            {!page.coverPicture && (!imageUploading || imageUploading === 'logo') && (
              <IconButton
                icon="image_upload"
                onClick={() => onImageInputClick(coverInput)}
                className="add-image-button">
                Add cover
              </IconButton>
            )}
            {!!imageUploading && imageUploading === 'cover' && (
              <span className="loading">
                {ICON['spinner']} Uploading {imageUploading}...
              </span>
            )}
            {!hasAnyDateDefined && datesModal('button-cancel button-size-sm')}
          </div>
          <div className="container title">
            <ResizableTextArea
              className="block title"
              ariaLabel="page title"
              placeholder="Title your page"
              value={page.title === defaultPageTitle ? '' : page.title}
              onChange={changeTitle}
              onKeyDown={handleEnterInTitle}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={page.title === defaultPageTitle || !page.title}
            />
          </div>
          {page.goal && (
            <div className="container title">
              <GoalBar goal={page.goal} />
            </div>
          )}
          {page.blocks.map((blockItem, blockIdx) => {
            const components = blockComponents(blockItem, blockIdx);
            return (
              <ContainerBlock key={blockItem.id} blockItem={blockItem} page={page}>
                <SideMenu
                  blocks={page.blocks}
                  blockIndex={blockIdx}
                  setFocusedElement={setFocusedElement}
                  setBlocks={newblocks => setPage({ ...page, blocks: newblocks })}
                  outsideRef={refOutside}
                />
                <DraggerMenu
                  blocks={page.blocks}
                  blockIndex={blockIdx}
                  setFocusedElement={setFocusedElement}
                  setBlocks={newblocks => setPage({ ...page, blocks: newblocks })}
                  outsideRef={refOutside}
                />
                {components[blockItem.type]}
              </ContainerBlock>
            );
          })}
        </section>
        <ErrorModal />
      </main>
    </>
  );
};

export default Editor;
