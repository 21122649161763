import { ColumnConfig, ObjectConfig } from 'components/ReportTable/types';
import { OrderItem, ResponseReportRecord, SubmissionQuestion } from 'api/data/response/types';

export type VisibleColumnsType = { [id: string]: boolean };

export interface ColumnsProps {
  questions: SubmissionQuestion[];
  visibleColumns: VisibleColumnsType;
  setVisibleColumns: (columns: VisibleColumnsType) => void;
  fixedColumns: ColumnConfig<ObjectConfig>[];
}

export type DropdownFiltersType = {
  [filterName: string]: { [id: string]: string };
};

export type FiltersType = {
  [filterName: string]: string[];
};

export type SortType = {
  column: string;
  direction: string;
};

export interface TableFilterProps {
  filterName: string;
  filterLabel: string;
  dropdownOptions: { [id: string]: string };
  filters: FiltersType;
  setFilters: (filters: FiltersType) => void;
}

export type ConfigurationType = {
  version?: number;
  query: {
    sort: SortType;
    filters: FiltersType;
    columns: VisibleColumnsType;
  };
};

export type firstQueryType = {
  sort: SortType;
  filters: FiltersType;
  columns: VisibleColumnsType;
};

export type ViewType = {
  id: string;
  name: string;
  configuration: ConfigurationType;
};

export type ViewReportType = {
  reportViews: ViewType[];
};

export type SaveViewReportType = {
  saveReportView: ViewType;
};

export interface ModalViewProps {
  handleOnSaveView: (viewName: string) => Promise<void>;
  loadingSaveView: boolean;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export type TableConfigType = ResponseReportRecord | OrderItem | ObjectConfig;

export const enum Condition {
  and = 'and',
  or = 'or',
}

export const DEFAULT_DROPDOWN_FILTERS: () => DropdownFiltersType = () => ({
  pageIds: {},
  // orderItemBlockTitles: {},
  // orderItemVariantTitles: {},
});

export const DEFAULT_COLUMNS: VisibleColumnsType = {
  space: true,
  page: true,
  name: true,
  email: true,
  participant_name: true,
  participant_email: true,
  inserted_at: true,
  block_title: true,
  variant_title: true,
  variant_quantity: true,
  items_quantity: true,
  subtotal: true,
  refunded_qty: true,
  refunded_amount: true,
  net_qty: true,
  total: true,
};

export const DEFAULT_VIEW: ViewType = {
  id: 'allSubmissions',
  name: 'View 1',
  configuration: {
    query: {
      sort: { column: 'inserted_at', direction: 'desc' },
      filters: { pageIds: [] },
      columns: DEFAULT_COLUMNS,
    },
  },
};
