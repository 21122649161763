import React, { FC } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useMutation, useQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import * as AuthQuery from 'graphql/auth.graphql';
import { extractQueryStringFromParams } from 'utils';
import useUserSession from 'hooks/useUserSession';
import PageWithLogoLine from 'components/PageWithLogoLine';
import SignupParticipant, { Participant } from '.';
import { CreateParticipantQuery } from 'api/data/user/types';
import { PageView } from 'api/data/pages/types';

type PageParams = {
  id: string;
};

const NewParticipant: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { data: userData } = useUserSession();
  const [, setLocation] = useLocation();
  const [saveParticipantMutation, { loading: loadingSaveParticipant }] = useMutation(PageQuery.SaveParticipant);
  const userIsLoggedIn = !!userData?.session.currentUser;
  const [createParticipantMutation, { loading: loadingCreateParticipant, data }] = useMutation<CreateParticipantQuery>(
    PageQuery.CreateParticipant,
    {
      refetchQueries: [AuthQuery.Session],
    },
  );
  const { data: pageData } = useQuery<PageView>(PageQuery.GetPageSpace, {
    variables: { id: params.id },
  });

  const createdParticipant = data?.createParticipant.participants?.at(0);

  const saveParticipant = async (participant: Participant) => {
    await saveParticipantMutation({
      variables: {
        participant: {
          fullName: participant.fullName,
          birthDate: participant.birthDate,
          id: createdParticipant?.id,
        },
        pageId: params.id,
      },
    });

    if (userIsLoggedIn) {
      setLocation(`/${pageData?.page.space?.slug}/pages/${params.id}/team/${createdParticipant?.slug}`);
    } else setLocation(`/${params.id}/p/${createdParticipant?.slug}/edit?signup=true`);
  };

  const createParticipant = async (participant: Participant) => {
    await createParticipantMutation({
      variables: {
        email: participant.email,
        pageSlug: params.id,
      },
    });
  };

  const handleSubmit = async (participant: Participant) => {
    if (createdParticipant?.id) await saveParticipant(participant);
    else await createParticipant(participant);
  };

  if (data?.createParticipant.action === 'existing')
    return (
      <PageWithLogoLine>
        <div>
          <p className="row justify-center title">Please check your email to login</p>
          <p className="row justify-center paragraph-x-small">
            Omella sent an email to confirm the account creation. <br /> <br />
          </p>
          <p className="row justify-center paragraph-x-small">You can close this tab.</p>
        </div>
      </PageWithLogoLine>
    );

  return (
    <SignupParticipant
      handleSubmit={handleSubmit}
      showFields={!!createdParticipant?.id}
      loading={loadingSaveParticipant || loadingCreateParticipant}
      email={extractQueryStringFromParams('email') || createdParticipant?.user.email}
    />
  );
};

export default NewParticipant;
