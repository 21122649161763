import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { isKYCd } from 'utils';
import * as PageQuery from 'graphql/page.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Details from '../Details';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import { Transactions } from 'components/ResponseDetail/Transactions';
import { Subscriptions } from 'components/ResponseDetail/Subscriptions';
import { ResponseInfo, PageResponses as PageResponsesType } from 'api/data/response/types';
import { Answers as AnswersType } from 'api/data/response/types';
import { Subscriptions as SubscriptionsType } from 'api/data/subscription/types';
import { Transactions as TransactionsType } from 'api/data/transaction/types';
import { PageView } from 'api/data/pages/types';
import { SubmissionType } from '../Details/SuccessCard';
import './style.scss';

export type SubmissionsDetailsTabsProps = {
  responseId: string;
  submissionType?: SubmissionType;
  participantSlug?: string;
};

const SubmissionDetailsTabs: FC<SubmissionsDetailsTabsProps> = ({ responseId, submissionType, participantSlug }) => {
  const { currentOrg } = useCurrentOrganization();

  const [tabContentKey, setTabContentKey] = useState('details');
  const { data: responseData, loading: responseLoading } = useQuery<ResponseInfo>(ResponseQuery.GetResponse, {
    variables: { id: responseId },
  });

  const { data: answersData } = useQuery<AnswersType>(ResponseQuery.Answers, {
    variables: { id: responseId },
  });

  const { data: pageData, loading: pageLoading } = useQuery<PageView>(PageQuery.GetPage, {
    variables: { id: responseData?.response.pageId },
    skip: !responseData?.response.pageId,
  });

  const { data: transactionsData } = useQuery<TransactionsType>(TransactionQuery.GetTransactions, {
    variables: { id: responseId, currentSpace: pageData?.page.space?.slug },
    skip: !currentOrg || !pageData,
  });

  const { data: subscriptionsData } = useQuery<SubscriptionsType>(SubscriptionQuery.GetSubscriptions, {
    variables: { id: responseId, currentSpace: pageData?.page.space?.slug },
    skip: !currentOrg || !pageData,
  });

  const { data: pageResponsesData } = useQuery<PageResponsesType>(ResponseQuery.PageResponses, {
    variables: { pageId: responseData?.response.pageId, currentSpace: pageData?.page.space?.slug },
    skip: !responseData?.response.pageId || !currentOrg || !pageData,
    fetchPolicy: 'cache-and-network',
  });

  if (responseLoading || pageLoading) return <div>Loading...</div>;

  const pageResponse = pageResponsesData?.pageResponses.responses.filter(response => response.id === responseId)[0];

  const tabContent: {
    [key: string]: JSX.Element;
  } = {
    details: (
      <Details
        page={pageData?.page}
        paramId={responseId}
        response={responseData?.response}
        answers={answersData?.answers}
        submissionType={submissionType}
        participantSlug={participantSlug}
      />
    ),
    payments: (
      <Transactions
        pageResponse={pageResponse}
        transactions={transactionsData?.transactions || []}
        pageId={pageData?.page.id}
        pageTitle={pageData?.page.title || ''}
      />
    ),
    subscriptions: (
      <Subscriptions
        responseId={responseId}
        subscriptions={subscriptionsData?.subscriptions || []}
        pageId={pageData?.page.id}
        pageSpaceSlug={pageData?.page.space?.slug}
      />
    ),
  };

  return (
    <div className="submission-details-tabs">
      {currentOrg?.status && (
        <div
          className={clsx('fixed-header', {
            'banner-space': !!currentOrg?.status && !isKYCd(currentOrg?.status),
          })}>
          <Tabs activeKey={tabContentKey}>
            <Tab label="Details" tabKey="details" onClick={setTabContentKey} />
            <Tab label="Payments" tabKey="payments" onClick={setTabContentKey} />
            <Tab label="Subscriptions" tabKey="subscriptions" onClick={setTabContentKey} />
          </Tabs>
        </div>
      )}
      <div>{tabContent[tabContentKey]}</div>
    </div>
  );
};

export default SubmissionDetailsTabs;
