import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { FetchResult, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { useLocation } from 'wouter';
import {
  addRequest,
  addRequests,
  clearRequests,
  generateRemindAfterDueDate,
  generateRemindBeforeDueDate,
  requestsVar,
  resetRequests,
  updateRequests,
} from 'api/data/request';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import * as RequestQuery from 'graphql/request.graphql';
import useDebounce from 'hooks/useDebounce';
import { showPlural, validateEmail } from 'utils';
import Message from 'components/Message';
import DropDown from '~/components/Menu/DropDown';
import DropDownItem from '~/components/Menu/DropDownItem';
import Modal from '~/components/Modal';
import RequestRow from './RequestRow';
import SendRequestsButton from 'components/SendRequestsButton';
import ToggleSwitch from 'components/ToggleSwitch';
import Tooltip from 'components/Tooltip';
import calendarReminder from 'assets/calendar_reminder.svg';
import {
  Request,
  Requests as RequestsType,
  PageRequests as PageRequestsType,
  SaveAsDraftRequest,
  SendRequests,
} from 'api/data/request/types';
import './style.scss';

interface RequestProps {
  pageId: string;
  slug: string;
  onShowPreview: (index: number) => void;
  selectedIndex?: number;
}

interface ToastProps {
  showMessage: boolean;
  message: string;
  type: 'succeeded' | 'failed' | undefined;
}

type DuplicatedEmails = {
  [key: string]: number;
};

const Requests: FC<RequestProps> = ({ pageId, slug, onShowPreview, selectedIndex }) => {
  const [createRequests] = useMutation<RequestsType>(RequestQuery.CreateRequests);
  const [deleteRequestMutation] = useMutation<RequestsType>(RequestQuery.DeleteRequest);
  const [draftRequests, { loading: loadingSaveAsDraft }] = useMutation<SaveAsDraftRequest>(
    RequestQuery.TransitionToDraft,
  );
  const [, setLocation] = useLocation();
  const requests = useReactiveVar<RequestsType>(requestsVar);
  const [duplicatedEmails, setDuplicatedEmails] = useState<DuplicatedEmails>({});
  const [disableSendRequest, setDisableSendRequest] = useState(true);
  const [reminders, setReminders] = useState({ beforeDueDate: true, afterDueDate: true });
  const [showClearAllModal, setShowClearAllModal] = useState(false);
  const [toastContent, setToastContent] = useState<ToastProps>({ showMessage: false, message: '', type: undefined });
  const debouncedRequests = useDebounce<RequestsType>(requests, 900);

  const { data: requestData, refetch: refetch } = useQuery<PageRequestsType>(RequestQuery.PageRequests, {
    variables: { pageId: pageId, status: ['DRAFT'] },
    fetchPolicy: 'network-only',
  });

  const updateRequestsFromQuery = () => {
    if (requestData) {
      if (requestData?.pageRequests.length > 0) {
        const draftRequests = requestData?.pageRequests.map(request => {
          return {
            id: request.id,
            fullName: request.fullName,
            email: request.email || '',
            dueDate: dayjs(request.dueDate).toDate(),
            remindBeforeDueDate: request.remindBeforeDueDate,
            remindAfterDueDate: request.remindAfterDueDate,
          };
        });

        addRequests(draftRequests);
      } else if (requestsVar().length === 0) {
        resetRequests();
      }
    }
  };

  useEffect(updateRequestsFromQuery, [requestData]);

  useEffect(() => {
    const newDuplicatedEmails: DuplicatedEmails = {};
    requests.forEach(request => {
      if (request.email) newDuplicatedEmails[request.email] = (newDuplicatedEmails[request.email] || 0) + 1;
    });

    setDuplicatedEmails(newDuplicatedEmails);

    setDisableSendRequest(
      requests.some(
        request =>
          (request.email || request.fullName?.trim()) && (!validateEmail(request.email) || !request.fullName?.trim()),
      ),
    );
  }, [requests]);

  const requestIsFilled = (request: Request) => request.email || request.fullName;

  const saveRequests = useCallback(
    (requests: RequestsType) => {
      const filteredRequests = requests.filter(requestIsFilled).map(request => {
        return {
          id: request.id,
          fullName: request.fullName,
          email: request.email.replaceAll(' ', ''),
          dueDate: dayjs(request.dueDate).format('YYYY-MM-DD'),
          remindBeforeDueDate: reminders.beforeDueDate ? generateRemindBeforeDueDate(request.dueDate) : undefined,
          remindAfterDueDate: reminders.afterDueDate ? generateRemindAfterDueDate(request.dueDate) : undefined,
        };
      });

      if (filteredRequests.length > 0)
        return createRequests({
          variables: {
            pageId,
            requests: filteredRequests,
          },
        });
    },
    [createRequests, pageId, reminders],
  );

  useEffect(() => {
    void saveRequests(debouncedRequests);
  }, [debouncedRequests, saveRequests]);

  const handleSaveAsDraft = async () => {
    await draftRequests({
      variables: {
        pageId,
        requests: requests.filter(requestIsFilled).map(request => request.id),
      },
    }).then(({ data }) => {
      data?.transitionToDraft.map(request => {
        updateRequests(
          'status',
          'DRAFT',
          requests.findIndex(item => {
            return item.id === request.id;
          }),
        );
      });

      setToastContent({ message: 'Success', showMessage: true, type: 'succeeded' });
    });
  };

  const handleClearAll = () => {
    resetRequests();
    requests.forEach(request => {
      if (request.id) void deleteRequestMutation({ variables: { requestId: request.id } });
    });
  };

  const handleSendRequests = async (sendRequests: (requestIds: string[]) => Promise<FetchResult<SendRequests>>) => {
    const requestIds = requests.filter(requestIsFilled).map(request => {
      return request.id;
    });

    await saveRequests(requests);
    const result = (await sendRequests(requestIds)).data?.sendRequests || { errors: requestIds, requests: [] };
    clearRequests();
    await refetch();
    updateRequestsFromQuery();

    if (result.errors.length === 0) {
      setLocation(`/${currentSpaceSlug() || ''}/pages/${slug}?requestSent=${result.requests.length}`);
    } else {
      const sentMessage =
        result.requests.length > 0
          ? `${result.requests.length} request${showPlural(result.requests.length)} sent. `
          : '';
      const errorMessage = `${result.errors.length} request${showPlural(result.errors.length)} could not be sent.`;
      setToastContent({
        message: sentMessage + errorMessage,
        showMessage: true,
        type: 'failed',
      });
    }
  };

  const filledRequestsQty = requests.filter(requestIsFilled).length;
  const settingsRef = useRef<HTMLElement>(null);
  const disableSendRequestButton = disableSendRequest || filledRequestsQty === 0 || loadingSaveAsDraft;

  const SendRequestButtonWrapper = () => (
    <div className="send-request-container">
      <SendRequestsButton
        disabled={disableSendRequestButton}
        onClick={sendRequests => handleSendRequests(sendRequests)}
        filledRequestsQty={filledRequestsQty}
        pageId={pageId}
        buttonText={`Send ${filledRequestsQty} request${showPlural(filledRequestsQty)}`}
        className="button-outline"
      />
    </div>
  );

  const handleOnChangeReminders = (field: 'beforeDueDate' | 'afterDueDate') => {
    const newReminders = {
      ...reminders,
      [field]: !reminders[field],
    };
    setReminders(newReminders);
  };

  return (
    <>
      <Modal
        handleOnCancel={() => setShowClearAllModal(false)}
        handleOnConfirm={() => {
          void handleClearAll();
          setShowClearAllModal(false);
        }}
        confirmlabel="Confirm"
        denyLabel="Cancel"
        header="Clear recipients"
        headerIcon="warning"
        visible={showClearAllModal}
        className="clear-recipients-modal">
        <p>
          Are you sure you want to clear this list? <b>This action cannot be undone.</b>
        </p>
      </Modal>
      <Message
        className="row align-center justify-center default-message"
        showMessage={toastContent.showMessage}
        setShowMessage={
          /* istanbul ignore next */
          visible => {
            setToastContent({ ...toastContent, showMessage: visible });
          }
        }
        type={toastContent.type}>
        {toastContent.message}
      </Message>
      <div className="share-container">
        <div className="row requests">
          <header className="request-header">
            <p>Add recipients and press send!</p>
            {disableSendRequest || filledRequestsQty === 0 ? (
              <Tooltip title="Please add the required fields" color="dark">
                <SendRequestButtonWrapper />
              </Tooltip>
            ) : (
              <SendRequestButtonWrapper />
            )}
          </header>
          <div className="request-reminders">
            <div>
              <img src={calendarReminder} alt="Calendar" />
              <p>
                Set Reminders <br /> <span>Send an email reminder before and after the due date.</span>
              </p>
            </div>
            <div>
              <ToggleSwitch
                toggleValue={reminders.beforeDueDate}
                label="3 days before"
                labelPosition="after"
                handleOnChange={() => handleOnChangeReminders('beforeDueDate')}
              />
              <ToggleSwitch
                toggleValue={reminders.afterDueDate}
                label="3 days after"
                labelPosition="after"
                handleOnChange={() => handleOnChangeReminders('afterDueDate')}
              />
            </div>
          </div>
          <div className="table">
            <header>
              <div className="col full-name">Full name*</div>
              <div className="col email">Email*</div>
              <div className="col due">Due*</div>
              {selectedIndex === -1 && (
                <div className="col actions">
                  <DropDown
                    className="request-dropdown"
                    outsideRef={settingsRef}
                    openedIcon="menu"
                    closedIcon="menu_open">
                    <DropDownItem
                      icon="save"
                      onClick={() => void handleSaveAsDraft()}
                      disabled={disableSendRequestButton}>
                      Save as drafts
                    </DropDownItem>
                    <DropDownItem
                      icon="trash_can"
                      onClick={() => setShowClearAllModal(true)}
                      disabled={disableSendRequestButton}>
                      Clear all
                    </DropDownItem>
                  </DropDown>
                </div>
              )}
            </header>
            <div className="table-body">
              {requests.map((request, index) => (
                <RequestRow
                  key={request.id}
                  index={index}
                  {...request}
                  selected={index === selectedIndex}
                  isEmailDuplicated={duplicatedEmails[request.email] > 1}
                  setShowDeleteMessage={visible => {
                    setToastContent({ ...toastContent, message: 'Deleted', showMessage: visible });
                  }}
                  onShowPreview={onShowPreview}
                  slug={slug}
                  showActions={selectedIndex === -1}
                />
              ))}
              <button onClick={addRequest} className="icon-button add-button row justify-center">
                Add recipient
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Requests;
