import React, { FC } from 'react';
import { RouteComponentProps } from 'wouter';
import clsx from 'clsx';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import * as PageQuery from 'graphql/page.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import LoggedPageContainer from 'components/LoggedPageContainer';
import SimpleHeader from 'components/Header/SimpleHeader';
import { extractQueryStringFromParams } from '~/utils';
import { ResponseInfo } from 'api/data/response/types';
import { PageView } from 'api/data/pages/types';
import { SubmissionType } from './Details/SuccessCard';
import SubmissionDetailsTabs from './Tabs';
import arrowLeft from 'assets/arrow_left.svg';
import './style.scss';

export type SubmissionsDetailsProps = {
  id: string;
};

const SubmissionDetails: FC<RouteComponentProps<SubmissionsDetailsProps>> = ({ params }) => {
  const { currentOrg } = useCurrentOrganization();

  const { data: responseData, loading: responseLoading } = useQuery<ResponseInfo>(ResponseQuery.GetResponse, {
    variables: { id: params.id },
  });

  const { data: pageData, loading: pageLoading } = useQuery<PageView>(PageQuery.GetPage, {
    variables: { id: responseData?.response.pageId },
    skip: !responseData?.response.pageId,
  });

  if (responseLoading || pageLoading) return <div>Loading...</div>;

  const backLocation =
    queryString.parse(window.location.search).from?.toString() || `pages/${pageData?.page.slug}/activity/reporting`;

  const submissionType = extractQueryStringFromParams('type');
  const participantSlug = extractQueryStringFromParams('participant');

  return (
    <LoggedPageContainer
      className={clsx('submission-details', { 'payer-receipt': !currentOrg })}
      organizationStatus={currentOrg?.status}
      header={submissionType && <SimpleHeader />}
      headerChildren={
        currentOrg?.status && (
          <div className="row second-header">
            <a href={`/${currentSpaceSlug() || ''}/${backLocation}`} className="link-back">
              <img src={arrowLeft} alt="back icon" />
            </a>
            <h1 className={clsx({ 'page-title': !currentOrg?.status })}>{responseData?.response.user?.fullName}</h1>
          </div>
        )
      }>
      <SubmissionDetailsTabs
        responseId={params.id}
        submissionType={submissionType as SubmissionType}
        participantSlug={participantSlug}
      />
    </LoggedPageContainer>
  );
};

export default SubmissionDetails;
