import React, { FC, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import QRCodeStyling from 'qr-code-styling';
import { useMutation } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import qrCodeDefault from 'defaults/qrCode';
import { participantAsAnAnswer } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Answers from 'pages/PageResponses/ResponseDetail/Answers';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import Modal from 'components/Modal';
import OrderItemDetails from 'components/OrderItem';
import ResponseActivityTypeModal from 'components/ResponseActivityTypeModal';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import SuccessCard, { SubmissionType } from './SuccessCard';
import { PageAnswer } from 'api/data/response/types';
import { PageDataDraftBlock } from 'api/data/pages/types';
import { Response, ResponseActivityType } from 'api/data/response/types';
import './style.scss';

interface DetailsProps {
  page: PageDataDraftBlock | undefined;
  paramId: string;
  response: Response | undefined;
  answers: PageAnswer[] | undefined;
  submissionType?: SubmissionType;
  participantSlug?: string;
}

const qrCode = new QRCodeStyling({
  ...qrCodeDefault,
  width: 92,
  height: 92,
  backgroundOptions: { color: '#FAFAFA' },
  type: 'svg',
  imageOptions: { ...qrCodeDefault.imageOptions, margin: 2 },
});

const Details: FC<DetailsProps> = ({ page, paramId, response, answers, submissionType, participantSlug }) => {
  const { currentOrg } = useCurrentOrganization();

  const [registerActivity] = useMutation(ResponseQuery.RegisterResponseActivity, {
    refetchQueries: [
      {
        query: ResponseQuery.GetResponse,
        variables: { id: paramId },
      },
    ],
  });
  const newActivityType = { name: '', public: false };
  const [editedActivityType, setEditedActivityType] = useState<Partial<ResponseActivityType>>();

  const [showModal, setShowModal] = useState(false);
  const [currentActivity, setCurrentActivity] = useState<Partial<ResponseActivityType>>();

  const handleResponseActivityClick = (responseActivityType: Partial<ResponseActivityType>) => {
    setCurrentActivity(responseActivityType);

    if (response?.paymentStatus !== 'PAID') {
      setShowModal(true);
    } else {
      confirmRegisterActivity(responseActivityType.id || '');
    }
  };

  const confirmRegisterActivity = (responseActivityId: string) => {
    void registerActivity({
      variables: {
        responseId: paramId,
        responseActivityTypeId: responseActivityId,
      },
    });

    setCurrentActivity(undefined);
    setShowModal(false);
  };

  const orderItems = response?.order.orderItems;

  const activityComponents = page?.responseActivityTypes?.reduce(
    (
      accumulator: {
        activityTypes: React.ReactNode[];
        activities: React.ReactNode[];
      },
      currentValue: ResponseActivityType,
    ) => {
      const activity = response?.responseActivities?.find(
        activity => activity.responseActivityType.id === currentValue.id,
      );
      if (activity) {
        accumulator.activities.push(
          <div className="activity-performed" key={currentValue.id}>
            <div className="title">
              <div>
                {ICONS['clock']}
                <span>{currentValue.name}</span>
              </div>

              {currentOrg && (
                <IconButton
                  className="activity-edit-button"
                  icon="edit"
                  onClick={() => setEditedActivityType(currentValue)}></IconButton>
              )}
            </div>
            <span>{dayjs(activity.insertedAt).format('M/D/YY h:mma')}</span>
          </div>,
        );
      } else {
        accumulator.activityTypes.push(
          <div className="response-activity-type-item" key={currentValue.id}>
            <button
              className={clsx('activity-main-button', { 'with-options': !!currentOrg })}
              key={currentValue.id}
              onClick={() => void handleResponseActivityClick(currentValue)}>
              {currentValue.name}
            </button>
            {currentOrg && (
              <IconButton
                className="activity-edit-button"
                icon="menu_open"
                onClick={() => setEditedActivityType(currentValue)}></IconButton>
            )}
          </div>,
        );
      }

      return accumulator;
    },
    { activityTypes: [], activities: [] },
  );

  const activityTypes = activityComponents?.activityTypes || [];
  const activities = activityComponents?.activities || [];

  const showActions = !!currentOrg || (page?.responseActivityTypes && page?.responseActivityTypes?.length > 0);

  const qrCodeRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (response?.id && node !== null) {
        qrCode.update({ data: `${window.location.hostname}/submissions/${response.id}` });
        qrCode.append(node);
      }
    },
    [response?.id],
  );

  const participantAnswer: PageAnswer[] = response?.participantMembership
    ? [participantAsAnAnswer(response?.participantMembership)]
    : [];

  const allAnswers = participantAnswer.concat(answers || []);

  return (
    <div className="submission-details-container">
      {submissionType && (
        <SuccessCard
          response={response}
          submissionType={submissionType as SubmissionType}
          participantSlug={participantSlug}
        />
      )}

      <div>
        {!currentOrg && (
          <div className="payer-header">
            <div>
              <p className="payer-name">{response?.user?.fullName}</p>
              <SafeHtmlRenderer className="receipt-message" content={page?.customBodyReceiptContent} />
            </div>
            <div className="qr-code">
              <div ref={qrCodeRef} />
              <p>Scan me</p>
            </div>
          </div>
        )}
        {showActions && (
          <>
            <h2>
              {currentOrg && 'Actions'}
              {currentOrg && (
                <button className="button-link" onClick={() => setEditedActivityType(newActivityType)}>
                  Add
                </button>
              )}
            </h2>

            {activityTypes.length > 0 && (
              <div className="response-activity-type-container">{activityTypes.map(activityType => activityType)}</div>
            )}
            {activities.length > 0 && (
              <div className="response-activity-container">{activities.map(activity => activity)}</div>
            )}
          </>
        )}

        {orderItems && orderItems.length > 0 && (
          <>
            <h2>Items</h2>
            {orderItems.map(orderItem => {
              const block = page?.blocks.find(item => item.id === orderItem.blockId);
              return <OrderItemDetails orderItem={orderItem} key={orderItem.id} block={block} />;
            })}
          </>
        )}

        {allAnswers && allAnswers?.length > 0 && (
          <>
            <h2>Answers</h2>
            <Answers respondentFullName={response?.fullName || ''} answers={allAnswers || []} />
          </>
        )}
      </div>
      {!!editedActivityType && (
        <ResponseActivityTypeModal
          pageId={response?.pageId || ''}
          activityType={editedActivityType}
          visible={!!editedActivityType}
          onClose={() => setEditedActivityType(undefined)}
          spaceSlug={page?.space?.slug}
        />
      )}
      <Modal
        header={currentActivity?.name}
        headerIcon="check_circle"
        handleOnCancel={() => setShowModal(false)}
        handleOnConfirm={() => confirmRegisterActivity(currentActivity?.id || '')}
        confirmlabel="Continue"
        denyLabel="Cancel"
        visible={showModal}
        className="confirmation-modal">
        <span>This submission has a {response?.paymentStatus?.toLocaleLowerCase()} payment.</span>
        <span>Are you sure you want to proceed?</span>
      </Modal>
    </div>
  );
};

export default Details;
