import React, { FC } from 'react';
import { findAnswer } from 'api/data/response';
import { Answer } from 'api/data/response/types';
import { QuestionBlock } from './types';

export interface ShortAnswerProps {
  data: QuestionBlock;
  placeholder: string;
  readOnly?: boolean;
  groupedAnswer?: Answer;
  handleAnswerChange?: (value: string) => void;
}

const ShortAnswer: FC<ShortAnswerProps> = ({
  data,
  readOnly,
  placeholder,
  groupedAnswer,
  handleAnswerChange,
}: ShortAnswerProps) => {
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'SHORT_ANSWER');

  return (
    <input
      className="input-answer"
      type="text"
      disabled={readOnly}
      placeholder={placeholder}
      maxLength={1000}
      aria-label="short answer"
      value={answer.value}
      onChange={({ target }) => handleAnswerChange && handleAnswerChange(target.value.trimStart())}
    />
  );
};

export default ShortAnswer;
